import React from "react"
import LinkTransition from "../common/link-transition"
// import Lottie from 'react-lottie';

import Image from "../image"

import { HeroWrapper } from "./styled.js"

// const Image = props => (
//   <StaticQuery
//     query={graphql`
//       query {
//         images: allFile {
//           edges {
//             node {
//               relativePath
//               name
//               childImageSharp {
//                 fluid(maxWidth: 600) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={data => {
//       const image = data.images.edges.find(n => {
//         return n.node.relativePath.includes(props.filename);
//       });
//       if (!image) {
//         return null;
//       }

//       return <Img alt={props.alt} fluid={image.node.childImageSharp.fluid} {...props} />;
//     }}
//   />
// );

const ContentHero = props => {
  return (
    <HeroWrapper>
      {props.visual ? (
        // <img src={props.visual} alt={(props.visualAlt) ? props.visualAlt : ((props.title) ? props.title : '')} className="visual" />
        <Image filename={props.visual} className="visual" />
      ) : null}

      {/* {(props.lottie) ? (
        <div className="animation-wrapper">
          <Lottie 
            options={{
              loop: false,
              autoplay: true, 
              animationData: props.lottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
              }
            }}
            ref={x => {
              // setTestAnimationsInst(x);
            }}
            // isPaused={testAnimationIsPaused}
            // direction={testAnimationDirection}
            speed={1}
          />
        </div>
      ) : null} */}

      <div className="content">
        {props.headlineUrl && props.headlineLabel ? (
          <LinkTransition to={props.headlineUrl} className="headline">
            {props.headlineLabel}
          </LinkTransition>
        ) : props.headlineLabel ? (
          <div className="headline">{props.headlineLabel}</div>
        ) : null}
        {props.title ? (
          <h1
            className="title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></h1>
        ) : null}
        {props.postAuthor || props.postDate ? (
          <div className="post-meta">
            {props.postAuthor ? (
              <span className="post-meta-author">
                {props.postAuthorAvatar ? (
                  <img
                    className="post-meta-avatar"
                    src={props.postAuthorAvatar}
                    alt={`Avatar of ${props.postAuthor}`}
                  />
                ) : null}
                By {props.postAuthor}
              </span>
            ) : null}
            {props.postAuthor && props.postDate ? <>&nbsp;-&nbsp;</> : null}
            {props.postDate ? (
              <span className="post-meta-author">{props.postDate}</span>
            ) : null}
          </div>
        ) : null}
        {props.text ? (
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: props.text }}
          ></div>
        ) : null}
        {props.promotext ? (
          <div
            className="promotext"
            dangerouslySetInnerHTML={{ __html: props.promotext }}
          ></div>
        ) : null}
        {props.subtext ? (
          <div
            className="subtext"
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          ></div>
        ) : null}
      </div>
    </HeroWrapper>
  )
}

export default ContentHero
