import styled from 'styled-components';
import variables, { fs, screenSize } from '../../utils/variables';

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1420px;
  min-height: calc(70vh -  200px);
  padding: 0 20px;
  margin: 0 auto;
  text-align: center;

  .visual {
    display: block;
    width: 1140px;
    max-width: 100%;
    max-height: 500px;
    margin: 0 auto 60px;
  }

  .animation-wrapper {
    display: block;
    width: 850px;
    max-width: 100%;
    max-height: 500px;
    margin: 0 auto 60px;
  }

  .content {
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto 50px;
  }

  .post-meta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${variables.colors.greyDark};
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    ${fs(16)}

    &-author {
      display: inline-flex;
      align-items: center;
    }

    &-avatar {
      margin: 0 12px 0 0;
    }

    + .text {
      margin-top: 75px;
      margin-bottom: -60px;
      color: ${variables.colors.greyDarker};
      ${fs(20)}
    }
  }

  .headline {
    display: inline-block;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    color: ${variables.colors.blue};
    text-decoration: none;
    margin: 0 0 32px 0;

    ${fs(16)}
  }

  .title {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    color: ${variables.colors.backgroundDark};

    ${fs(46)}
  }

  .text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: ${variables.colors.greyDarker};
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;

    ${fs(20)}
    ${screenSize('max-width', 910)}

    & + .subtext {
      margin-top: 30px;
    }
  }

  .promotext {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    color: ${variables.colors.greyDarker};
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;

    ${fs(18)}
    ${screenSize('max-width', 910)}

    strong {
      display: block;
      color: ${variables.colors.blue};
      font-weight: 500;
    }

    & + .subtext {
      margin-top: 30px;
    }
  }

  .subtext {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: ${variables.colors.greyDarker};
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;

    ${fs(20)}
    ${screenSize('max-width', 910)}
  }
  
`;