import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section"
import ContentHero from "../components/content-hero"
import SubFeatureDetail from "../components/subfeature-detail"

import Ready from "../components/ready"

import illuReady from "../images/illu-ready.svg"

import illuHero from "../images/organize-together-pitch_top.svg"
import illuHero2 from "../images/sub-product-tour-1.svg"

const ContentSample = () => {
  const channelAnimation = "/animations/2020-09-Ludus-04-channels_EDIT01.json"
  const liveCollaborationAnimation =
    "/animations/2020-09-Ludus-01-collaboration.json"
  const thirdPartyAnimation = "/animations/2020-09-Ludus-12-cloud.json"

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  return (
    <Layout
      socialDark={true}
      currentPage="organize-together"
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Organize together" />

      <Section>
        <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "43%" : "24%",
            paddingBottom: currentBreakPoint.type === "mobile" ? "75%" : "27%",
            top: currentBreakPoint.type === "mobile" ? "130px" : "190px",
            right: "0",
            background: `transparent url(${illuHero}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <ContentHero
          headlineLabel="Product tour"
          headlineUrl="/product-tour"
          title="Organize together"
          text="<p>We make it very easy and reliable to work together on the same presentation. In Ludus we try to find the right balance between being able to work all together live and the moment when you need some sense of control or more privacy. We let you define some boundaries for that collaboration so you remain in full control. No more bad surprises.</p>"
        />
      </Section>

      <Section>
        <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? "40%" : "20%",
            paddingBottom: currentBreakPoint.type === "mobile" ? "75%" : "27%",
            top: "-5%",
            left: "0",
            background: `transparent url(${illuHero2}) no-repeat 0 50%`,
            backgroundSize: "contain",
          }}
        />

        <SubFeatureDetail
          title="Channels"
          text="Thanks to the channels you can define different workspaces with different user rights. That means you can keep your sensitive presentations confidential. You can also work in your very own channel when you want to play it solo. "
          details={[
            {
              title: "Manage your team members.",
              text:
                "Define channels access and a role for each member (content editor, contributor, guest, admin) to avoid bad surprises.",
            },
            {
              title: "Private channels",
              text:
                "By default a channel is shared with all your team members, but you can define restricted channels whenever you need.",
            },
            {
              title: "External members",
              text:
                "Working with your team is fun, but don't limit yourself to that. You can invite external members to specific channels.",
            },
          ]}
          visual={{
            alt: "Channels",
          }}
          animation={channelAnimation}
        />
      </Section>

      <Section light={true}>
        <SubFeatureDetail
          reverse={true}
          title="Live Collaboration"
          text="Fluidify your team’s workflow with the ability to simultaneously edit the same presentation with your coworkers."
          details={[
            {
              title: "Reliable and virtually unlimited",
              text:
                "The editor is built on robust technology allowing virtually unlimited editors on the same slide. ",
            },
            {
              title: "It's not just about your team",
              text:
                "An external editor can also join the party, as long as he's a member of the channel where the presentation is stored (and has the role of content editor).",
            },
            // {
            //   title: "Solo mode",
            //   text:
            //     'We know sometimes live collaboration can be a bit cumbersome. The solo mode let you work "alone" in the current slide so other can\'t come and start messing up with what you did not even start to edit.  ',
            // },
            // {
            //   title: "Publish workflow",
            //   text:
            //     "We know live collaboration is fun and everyone loves it but truth is it can bring a bit of uncertainty on the table. As everyone can potentially edit anything, we must find strategies to avoid chaos.",
            // },
            {
              title: "Two versions of the same presentation",
              text:
                "Each Ludus presentation always has two versions: a preview version and a published version. The preview version is only visible by you and your team. The published version is what can be seen from external people.",
            },
            {
              title: "",
              text:
                "This means you'll never share work-in-progress or unvalidated work to your clients. You'll always have to press the publish button first. You always remain in control.",
            },
          ]}
          visual={{
            alt: "Live collaboration",
          }}
          animation={liveCollaborationAnimation}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          title="3d-party syncing"
          text="Collaboration is not just about Ludus. It's also how Ludus itself interact with other players. Let's check how you can seamlessly add Ludus to your current ecosystem."
          details={[
            {
              title: "Dropbox",
              text:
                "There are two ways to use Dropbox in Ludus: dynamic link or via the Dropbox picker. The dynamic link lets you keep your Dropbox images in sync with your slides (if you update an image on Dropbox it's updated in Ludus as well, on every slide you're using it). The Dropbox picker lets you browse your Dropbox content without leaving Ludus. ",
            },
            {
              title: "Google Drive",
              text:
                "The Google Drive integration lets you create Ludus presentations directly from your Drive and keep them in sync, but only if you want to. ",
            },
            {
              title: "Adobe Creative Cloud Libraries",
              text:
                "If you are using Adobe Creative Cloud Libraries, you can browse them directly from Ludus. You can then import images, videos and GIFs but also your Adobe colors, meaning you can very quickly integrate your current creative system from Adobe with Ludus. ",
            },
          ]}
          visual={{
            alt: "Channels",
          }}
          animation={thirdPartyAnimation}
        />
      </Section>

      <Section>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Section>

      <Section light={true}>
        <div
          style={{
            display:
              currentBreakPoint.bp === "m" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "xs"
                ? "none"
                : "block",
            position: "absolute",
            zIndex: "4",
            width: "35%",
            paddingBottom: "35%",
            bottom: "0",
            right: "0",
            background: `transparent url(${illuReady}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />
        <Ready />
      </Section>
    </Layout>
  )
}

export default ContentSample
