import styled from 'styled-components';
import variables, { mq, fs } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: 0 auto;
  max-width: 85vw;
  min-height: calc(80vh -  180px);
  padding: 150px 5vw 80px;

  ${mq('l')} {
    max-width: 100%;
  }

  ${mq('m')} {
    padding: 120px 35px 30px;
  }

  ${mq('s')} {
    padding: 120px 20px 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -30px -36px;
  flex-direction: ${(props) => (props.reverse) ? 'row-reverse' : 'row'};

  ${mq('xxl')} {
    //justify-content: flex-start;
  }
  
  ${mq('s')} {
    flex-direction: column-reverse;
  }

  .inner {
    width: calc(50% - 25px);

    ${mq('s')} {
      width: calc(100% - 100px);
    }
  }

  .visual {
    width: calc(50% - 25px);

    img {
      max-width: 100%;
    }

    ${mq('s')} {
      width: calc(100% - 100px);
      margin-bottom: 50px;
    }
  }

  .tag-soon {
    display: inline-block;
    padding: 5px 11px;
    margin-left: 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: ${variables.colors.white};
    background: ${variables.colors.blue};
    ${fs(16)}
  }

  .title {
    max-width: 27vw;
    margin-bottom: 40px;
    color: ${variables.colors.backgroundDark};
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.3;

    ${fs(40)}

    ${mq('l')} {
      max-width: 50vw;
    }

    ${mq('m')} {
      max-width: 75vw;
    }

    ${mq('s')} {
      max-width: 100%;
    }
  }

  .text {
    margin-bottom: 45px;
    color: ${variables.colors.backgroundDark};
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.7;

    ${fs(18)}
  }

  .details {
    &-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      ${mq('s')} {
        flex-direction: column;
      }

      .title {
        margin-bottom: 10px;
        padding-right: 20px;
        width: 35%;
        font-family: 'Bossa', sans-serif;
        font-weight: 700;
        color: ${variables.colors.backgroundDark};

        ${fs(18)}

        ${mq('s')} {
          width: 100%;
        }
      }

      .text {
        margin-bottom: 0;
        width: 65%;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        color: ${variables.colors.greyDarker};

        ${fs(16)}

        ${mq('s')} {
          width: 100%;
        }

        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .link {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    color: ${variables.colors.red};
    text-decoration: none;
    float: right;

    ${fs(16)}

    svg {
      width: 18px;
      height: 8px;
      margin-left: 10px;

      path {
        fill: ${variables.colors.red};
      }
    }

    &:hover {
      color: ${variables.colors.blue};
      
      svg,
      svg path {
        fill: ${variables.colors.blue};
      }
    }
  }

`;