import React, {useRef, useEffect} from 'react';
import LinkTransition from '../common/link-transition';
import bodymovin from 'lottie-web';

import { Container, Content } from './styled.js';

const SubFeatureDetail = (props) => {
  
  let animation = useRef(null);

  useEffect(() => {
    if(animation && animation.current) {
      bodymovin.loadAnimation({
        useSubFrames: false,
        wrapper: animation.current,
        renderer: 'svg',
        autoplay: true,
        loop: true,
        path: props.animation
      });
    }
  }, []);

  return (
    <Container>
      <Content reverse={props.reverse}>
        <div className="inner">
          <h2 className="title">
            {props.title}
            {props.soon ? (<span className="tag-soon">Soon</span>) : null}
          </h2>
          <div className="text" dangerouslySetInnerHTML={{__html: props.text}}></div>
          {(props.details) ? (
          <div className="details">
            {props.details.map(detail => {
              return (<div className="details-item">
                <h3 className="title">{detail.title}</h3>
                <div className="text"><p>{detail.text}</p></div>
              </div>);
            })}
          </div>
          ) : null}
          {(props.link) ? (
            <LinkTransition to={props.link.url} className="link">
              {props.link.label}
              <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7053 6.63864V8.60241C16.7053 9.70557 18.0391 10.258 18.8191 9.47798L22.021 6.27614C22.5046 5.79258 22.5045 5.00857 22.021 4.525L18.8191 1.32316C18.0391 0.543113 16.7053 1.09558 16.7053 2.19874V4.16215H1.8464C1.16254 4.16215 0.608154 4.71653 0.608154 5.40039C0.608154 6.08425 1.16254 6.63864 1.8464 6.63864H16.7053Z"/>  
              </svg>
            </LinkTransition>
          ) : null}
        </div>
        <div className="visual">
          {(props.animation) ? (
            // <Lottie 
            //   options={{
            //     loop: true,
            //     autoplay: true, 
            //     animationData: props.animation,
            //     rendererSettings: {
            //       preserveAspectRatio: 'xMidYMid meet'
            //     }
            //   }}
            // />
            <div className="animation" ref={animation} style={{width: '100%', height: '100%'}} />
          ) : (
            (props.visual && props.visual.image && props.visual.alt ) ? (
              <img src={props.visual.image} alt={props.visual.alt} />
            ) : null
          )}
        </div>
      </Content>
    </Container>
  )
}

export default SubFeatureDetail;
